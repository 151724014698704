<template>
  <div class="join">
    <img class="img-bg" src="@/assets/img/login-bg1.jpg" />
    <div class="join-con">
      <h2><img src="@/assets/img/icon_gdia.svg" />{{ $t('front.common.signup') }}</h2>
      <ul class="txt">
         <li>회원가입 시 모든항목을 정확하게 기재하시기 바랍니다.</li>
         <li>회원데이터는 안전한 서버에서 안전하게 보관됩니다.</li>
      </ul>
      <div class="join-in">
        <span class="jointxt">{{ $t('front.common.memId') }}</span>
        <div class="joininputwrap"><input class="joininput" type="text" v-model="model.memId" :readonly="true" @focus="onFocus" :placeholder="$t('front.signup.memIdPlaceholder')"/></div>

        <span class="jointxt">{{ $t('front.common.password') }}</span>
        <div class="joininputwrap"><input class="joininput" type="password" v-model="model.memPass" :placeholder="$t('front.signup.passwordPlaceholder')"/></div>
        <span class="jointxt">{{ $t('front.mypage.passwordOk') }}</span>
        <div class="joininputwrap"><input class="joininput" type="password" v-model="model.memPassCheck" :placeholder="$t('front.signup.passwordPlaceholder')"/></div>

        <span class="jointxt">{{ $t('front.common.cashOutPass') }}</span>
        <div class="joininputwrap"><input class="joininput" maxlength="4" inputmode="numeric" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"  pattern="[0-9]*" id="cashOutPass" type="text" v-model="model.cashOutPass" :placeholder="$t('front.signup.cashOutPassPlaceholder')"/>
        </div>

        <span class="jointxt">{{ $t('front.common.nickName') }}</span>
        <div class="joininputwrap"><input class="joininput" type="text" v-model="model.memNick" :placeholder="$t('front.signup.nickNamePlaceholder')"/></div>

        <span class="jointxt">{{ $t('front.common.phone') }}</span>
        <div class="joininputwrap"><input class="joininput" type="text" v-model="model.memPhone" :placeholder="$t('front.signup.phonePlaceholder')"/></div>
        <span class="jointxt">{{ $t('front.signup.recommandIdPlaceholder') }}</span>
        <div class="joininputwrap" v-if="!joinRecommenderId"><input class="joininput" type="text" v-model="model.recommenderId" :placeholder="$t('front.common.recommandId')"/></div>
        <div class="joininputwrap" v-else>{{joinRecommenderId}}</div>
        <span class="jointxt">{{ $t('front.common.bankSelect') }}</span>
        <div class="joinin">
          <select v-model="model.bank">
            <option selected :value="''" disabled>{{ $t('front.common.bankSelect') }}</option>
            <template v-for="item in bankList" v-bind:key="item.bankCode">
              <option :value="item.bankCode">{{ item.bankNameKr }}</option>
            </template>
          </select>
          <div class="joininputwrap"><input class="joininput" type="text" :placeholder="$t('front.common.bankName')"
                                            v-model="model.memName"/></div>
        </div>
        <span class="jointxt">{{ $t('front.common.bankAcc') }}</span>
        <div class="joininputwrap"><input class="joininput" type="number"
                                          :placeholder="$t('front.common.bankAccOnlyNumber')" v-model="model.bankacc"/>
        </div>
        <a class="joinbtns" @click="onSubmit('mobileMain')">{{ $t('front.common.signup') }}</a>
        <!--ul class="sns">
          <li><a><img src="@/assets_mobile/img/cacao.png"><span class="br">test1234</span></a></li>
          <li><a><img src="@/assets_mobile/img/telegram.png"><span class="bl">test1234</span></a></li>
        </ul-->

      </div>
    </div>
  </div>
</template>

<script>
import join from '@/components/member/Join'

export default {
  name: 'join',
  mixins: [join]
}
</script>
<style scoped>
#cashOutPass {
  -webkit-text-security: disc;
}
</style>
<style scoped src="@/styles_mobile/join.css"></style>
<style scoped src="@/styles_mobile/header.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
